import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Structured lists`}</em>{` group content that is similar or related, such as terms and definitions.`}</p>
    <h2 {...{
      "id": "content"
    }}>{`Content`}</h2>
    <ul>
      <li parentName="ul">{`Row height varies based on content and can expand to fit multiple lines.`}</li>
      <li parentName="ul">{`In a single list, all rows do not have to be the same height.`}</li>
      <li parentName="ul">{`Column widths can either be equally proportional or proportioned based on content.`}</li>
      <li parentName="ul">{`If a list extends past 25 items, consider using a `}<a parentName="li" {...{
          "href": "/components/data-table"
        }}>{`data table`}</a>{` to present this larger set of content.`}</li>
      <li parentName="ul">{`A maximum of one paragraph of text is recommended per row.`}</li>
      <li parentName="ul">{`Nesting items is not recommended, as structured lists are used to present simple data. If you have additional content that needs to be shown, consider using a `}<a parentName="li" {...{
          "href": "/components/data-table"
        }}>{`data table`}</a>{`, which supports nesting items.`}</li>
    </ul>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "47.58928571428571%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Structured list to present definitions.",
        "title": "Structured list to present definitions.",
        "src": "/static/b51c4097df8db91e721f32161dd0e2a9/fb070/structured-list-usage-2.png",
        "srcSet": ["/static/b51c4097df8db91e721f32161dd0e2a9/d6747/structured-list-usage-2.png 288w", "/static/b51c4097df8db91e721f32161dd0e2a9/09548/structured-list-usage-2.png 576w", "/static/b51c4097df8db91e721f32161dd0e2a9/fb070/structured-list-usage-2.png 1152w", "/static/b51c4097df8db91e721f32161dd0e2a9/fb104/structured-list-usage-2.png 1728w", "/static/b51c4097df8db91e721f32161dd0e2a9/8fefe/structured-list-usage-2.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "interaction"
    }}>{`Interaction`}</h2>
    <p>{`Structured lists can be single-select if a user is choosing between a set of options.`}</p>
    <p><strong parentName="p">{`Guidelines:`}</strong></p>
    <ul>
      <li parentName="ul">{`Only one item can be selected from the list.`}</li>
      <li parentName="ul">{`By default, one option should be selected.`}</li>
      <li parentName="ul">{`If you need to select multiple items, use a `}<a parentName="li" {...{
          "href": "/components/data-table"
        }}>{`data table.`}</a></li>
      <li parentName="ul">{`When the user selects an item from the list, the selected row will appear with the `}<inlineCode parentName="li">{`checkmark--filled`}</inlineCode>{` icon.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      